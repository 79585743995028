<template>
  <div class="main-content">
    <div class="layout-px-spacing mb-5">
      <div class="layout-top-spacing">
        <div class="layout-spacing bg-white p-5 shadow">
          <form @submit.prevent="generateInvoice()">
            <div class="mb-4">
              <div class="invoice-00002">
                <div class="content-section" data-intro="A quick tour 👋">
                  <div class="row inv--head-section">
                    <div class="col-sm-6 col-12">
                      <img
                        :src="`${url}/assets/img/gerocare-logo.png`"
                        alt=""
                        style="width: 60%; max-width: 300px"
                      />
                    </div>
                    <div
                      class="col-sm-6 col-12 align-self-center text-sm-right"
                    >
                      <div class="company-info">
                        <span style="font-weight: bolder"
                          >Gerocare Solutions Limited</span
                        >
                        <br />
                        No. 7a Milverton Road, Ikoyi, Lagos.<br />
                        +234(0)7014448844 <br />
                        <a href="mailto:gerocare@gerocare.org">
                          gerocare@gerocare.org
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="row inv--detail-section mt-5">
                    <div class="col-sm-7 align-self-center">
                      <p class="inv-to">To:</p>
                    </div>

                    <div class="col-sm-7 align-self-center">
                      <p
                        class="inv-customer-name"
                        data-intro="Enter name of customer"
                      >
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Customer Name"
                          v-model="form.name"
                        />
                      </p>
                      <p
                        class="inv-street-addr"
                        data-intro="Enter customer address or location"
                      >
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Customer Address"
                          v-model="form.address"
                        />
                      </p>
                      <p
                        class="inv-email-address"
                        data-intro="Enter email address of the customer"
                      >
                        <input
                          type="email"
                          class="form-control"
                          placeholder="Customer Email"
                          v-model="form.email"
                        />
                      </p>
                    </div>

                    <div class="col-sm-5 align-self-center order-2">
                      <p
                        class="inv-created-date"
                        data-intro="Enter the date the invoice was generated"
                      >
                        <span class="inv-title sr-only">Invoice Date : </span>
                        <span class="inv-date">
                          <b-form-datepicker
                            v-model="form.invoiceDate"
                            class="mb-2"
                            placeholder="Issue Date"
                          ></b-form-datepicker>
                        </span>
                      </p>
                      <p
                        class="inv-created-date"
                        data-intro="Enter the due date for the invoice"
                      >
                        <span class="inv-title sr-only">Due Date : </span>
                        <span class="inv-date">
                          <b-form-datepicker
                            v-model="form.dueDate"
                            class="mb-2"
                            placeholder="Due Date"
                          ></b-form-datepicker>
                        </span>
                      </p>
                    </div>
                  </div>

                  <div class="inv--product-table-section mt-3">
                    <div
                      class="text-right"
                      data-intro="Click here to add invoice items. A corresposing delete button will also be shwon for the items"
                    >
                      <button
                        type="button"
                        @click.prevent="addField()"
                        class="btn btn-success btn-rounded"
                      >
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>

                    <div class="table-responsive">
                      <table class="table">
                        <thead class="">
                          <tr>
                            <th scope="col">Items</th>
                            <th class="text-right" scope="col">Description</th>
                            <th class="text-right" scope="col">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in invoiceItems"
                            :key="index"
                          >
                            <td data-intro="Enter the name of the item">
                              <input
                                type="text"
                                class="form-control"
                                v-model="invoiceItems[index].item"
                              />
                            </td>
                            <td
                              class="text-right"
                              data-intro="Enter the item's description"
                            >
                              <textarea
                                class="form-control"
                                rows="1"
                                v-model="invoiceItems[index].description"
                              >
                              </textarea>
                            </td>
                            <td
                              class="text-right"
                              data-intro="Enter the item's amount"
                            >
                              <money
                                v-model="invoiceItems[index].amount"
                                v-bind="money"
                                class="form-control"
                              ></money>
                            </td>
                            <td>
                              <button
                                class="btn btn-sm btn-danger btn-rounded"
                                type="button"
                                v-show="invoiceItems.length > 1"
                                @click.prevent="removeField(index)"
                              >
                                <i class="fa fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="row mt-4 pt-4">
                    <div class="col-sm-5 col-12 order-sm-0 order-1">
                      <div class="inv--payment-info">
                        <div class="row">
                          <div
                            class="col-12"
                            data-intro="Select the bank accounts to be included on the invoice"
                          >
                            <b-form-group
                              label="Bank Accounts"
                              v-slot="{ ariaDescribedby }"
                            >
                              <b-form-checkbox
                                v-for="option in options"
                                v-model="selected"
                                :key="option.value"
                                :value="option.value"
                                :aria-describedby="ariaDescribedby"
                                name="flavour-3a"
                              >
                                {{ option.text }}
                              </b-form-checkbox>
                            </b-form-group>
                          </div>

                          <div
                            class="col-12 mt-3"
                            data-intro="Choose the payment status. If paid, we will generate a receipt, if not we will generate an invoice."
                          >
                            <p class="text-bold">Payment Status</p>
                            <div class="n-chk">
                              <label
                                class="new-control new-radio new-radio-text radio-success"
                              >
                                <input
                                  type="radio"
                                  class="new-control-input"
                                  value="paid"
                                  v-model="form.pay_status"
                                />
                                <span class="new-control-indicator"></span
                                ><span class="new-radio-content">Paid</span>
                              </label>
                            </div>
                            <div class="n-chk">
                              <label
                                class="new-control new-radio new-radio-text radio-danger"
                              >
                                <input
                                  type="radio"
                                  class="new-control-input"
                                  v-model="form.pay_status"
                                  value="not paid"
                                />
                                <span class="new-control-indicator"></span
                                ><span class="new-radio-content">Not Paid</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-7 col-12 order-sm-1 order-0">
                      <div class="inv--total-amounts text-sm-right">
                        <div class="row">
                          <div class="col-sm-8 col-7">
                            <p class="">Sub Total:</p>
                          </div>
                          <div
                            class="col-sm-4 col-5"
                            data-intro="The subtotal without taxes is shown here"
                          >
                            <p class="">{{ formatMoney(subTotal) }}</p>
                          </div>
                          <div class="col-sm-8 col-7">
                            <p class="">Tax:</p>
                          </div>
                          <div
                            class="col-sm-4 col-5"
                            data-intro="Customize the tax charge here"
                          >
                            <p class="">
                              <select class="form-control w-75" v-model="tax">
                                <option value="0" selected>0%</option>
                                <option value="4">4%</option>
                                <option value="7.5">7.5%</option>
                                <option value="10">10%</option>
                                <option value="15">15%</option>
                              </select>
                            </p>
                          </div>
                          <div class="col-sm-8 col-7 grand-total-title">
                            <h4 class="">Grand Total :</h4>
                          </div>
                          <div
                            class="col-sm-4 col-5 grand-total-amount"
                            data-intro="The overall total is shown here"
                          >
                            <h4 class="">{{ formatMoney(grandTotal) }}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mt-4">
                    <b-form-group>
                      <b-form-checkbox
                        v-model="form.send_to_customer"
                        size="lg"
                        switch
                      >
                        Do you want send the
                        {{
                          form.pay_status == "not paid" ? "invoice" : "receipt"
                        }}
                        email to the customer?
                        <span
                          :class="
                            'font-weight-bold text-' +
                            (form.send_to_customer ? 'primary' : '')
                          "
                        >
                          {{ form.send_to_customer ? "YES" : "NO" }}
                        </span>
                      </b-form-checkbox>
                    </b-form-group>

                    <b-btn type="submit" :disabled="loading" block>
                      <b-spinner small v-if="loading"></b-spinner>
                      <span v-else>
                        Generate
                        <span v-if="form.pay_status == 'not paid'">
                          Invoice
                        </span>
                        <span v-else>Receipt</span>
                        <i class="fa fa-paper-plane"></i>
                      </span>
                    </b-btn>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { endpoints } from "@/utils/endpoints";
import { http } from "@/utils/http";
import { mapGetters } from "vuex";
import { QuickInvoiceItem } from "@/models/quickinvoiceitem.js";
import { MoneyMask } from "@/models/invoice";

export default {
  name: "QuickInvoice",

  computed: {
    ...mapGetters(["url", "user"]),

    subTotal: function () {
      return this.invoiceItems.reduce((n, { amount }) => n + Number(amount), 0);
    },

    grandTotal: function () {
      return this.subTotal + (this.subTotal * this.tax) / 100;
    },
  },

  data() {
    return {
      loading: false,

      form: {
        name: "",
        address: "",
        email: "",
        invoiceDate: "",
        dueDate: "",
        pay_status: "not paid",
        send_to_customer: false,
      },

      money: new MoneyMask(),

      tax: 0,
      selected: ["sterling"],
      options: [
        { text: "Sterling", value: "sterling" },
        { text: "Zenith", value: "zenith" },
        { text: "Ecobank", value: "ecobank" },
        { text: "United Bank of Africa", value: "uba" },
      ],

      invoiceItems: [new QuickInvoiceItem()],
    };
  },

  methods: {
    addField() {
      this.invoiceItems.push(new QuickInvoiceItem());
    },
    removeField(index) {
      this.invoiceItems.splice(index, 1);
    },

    generateInvoice() {
      if (this.form.name == "") {
        this.$toast.error("Missing input fields. Please try again.");
      } else {
        this.loading = true;
        http
          .post(endpoints.QUICK_INVOICE, {
            form: this.form,
            tax: this.tax,
            items: this.invoiceItems,
            banks: this.selected,
            total: this.subTotal,
            overall_total: this.grandTotal,
          })
          .then(() => {
            this.loading = false;

            if (this.pay_status == "paid") {
              this.$toast.success("Receipt generated successfully");
            } else {
              this.$toast.success("Invoice generated successfully");
            }

            this.invoiceItems = [new QuickInvoiceItem()];
            this.form.name = "";
            this.form.email = "";
            this.form.address = "";
            this.form.invoiceDate = "";
            this.form.dueDate = "";
            this.form.send_to_customer = false;
            this.tax = 0;
          })
          .catch((error) => {
            this.loading = false;
            this.$toast.error(
              error.response?.data?.message || error.response?.message
            );
          });
      }
    },
  },
};
</script>

<style scoped src="@/assets/css/apps/invoice.css"></style>
